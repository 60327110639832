.background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.modal {
  display: flex;
  position: relative;
  flex-flow: column nowrap;
  justify-content: center;
  font-size: 6rem;
  color: crimson;
}
