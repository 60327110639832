.sitrep {
  background-color: #70809042;
  border: 1px solid slategray;
  width: 40%;
  display: flex;
  flex-flow: column nowrap;
}

.title {
  background-color: slategray;
  color: black;
  padding-left: 0.2rem;
  font-weight: bold;
}

.runnerGrid {
  display: flex;
  flex-flow: column nowrap;
  padding: 1rem;
  gap: 0.4rem;
}
.runnerGridHeader {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  font-weight: bold;
  color: crimson;
  padding-bottom: 0.5rem;
  border-bottom: 1px slategrey dashed;
}

.runner {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.softLabel {
  font-size: 0.8rem;
  background-color: black;
  margin: 0 0.1rem;
  padding: 0 0.1rem;
}
.selectedRunner {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  background-color: deeppink;
  color: white;
}

.runnerGridNo,
.runnerGridCy {
  flex: 0.8;
}

.runnerGridSt {
  flex: 1.4;
}

.runnerGridSo,
.runnerGridHa {
  flex: 3;
}
