.console {
  border: 1px solid seashell;
  width: 60%;
  height: 20vh;
  padding: 1vw;
  max-height: 20vh;
  overflow: auto;
  display: flex;
  flex-flow: column nowrap;
}

.command {
  color: white;
}

.activeCommand {
  color: orange;
}

.commandLine {
  border: 0;
  color: orange;
  font-family: monospace;
}

.commandLineInput {
  background-color: black;
  border: 0;
  outline: none;
  color: orange;
  font-family: monospace;
}

.commandLineInput:focus {
  border: 0;
  outline: none;
}
