.hackerView {
  color: slategray;
  font-family: monospace;
  display: flex;
  flex-flow: column nowrap;
  gap: 1rem;
  padding: 2rem;
  background-color: black;
}

.hackerView header {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.deckLogo {
  font-size: 2rem;
  color: crimson;
}

.linkContainer {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
}

.traceWarning {
  justify-content: right;
  color: dimgray;
}
.hackerView footer {
  display: flex;
  flex-flow: row nowrap;
  gap: 1rem;
  justify-content: space-between;
}
